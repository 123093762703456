<template>
  <div
    class="f2g-subtitle"
    :class="{ 'margin-top': marginTop }"
    :style="'color:' + colorPrimary"
  >
    <v-layout align-center>
      <slot />
      <F2gIcon
        v-if="hasHelpTip && checkHelpTextSettings"
        class="mx-2 icon"
        :height="17"
        :width="17"
        value="question-mark"
        @click.stop="showHelpCard = !showHelpCard"
      />
      <transition name="fade">
        <F2gCard
          v-if="showHelpCard"
          class="helpCard"
          :style="
            helpCardFixedWidth
              ? `width: ${helpCardFixedWidth}px;${setStyle()}`
              : `width: 100%;${setStyle()}`
          "
          ><div class="pa-3">
            <span class="mt-1 message" v-html="helpMessage"></span>
          </div>
        </F2gCard>
      </transition>
    </v-layout>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "f2g-Subtitle",
  props: {
    marginTop: { required: false, type: Boolean, default: true },
    hasHelpTip: { type: Boolean, required: false, default: false },
    isForm: { type: Boolean, required: false, default: false },
    helpCardFixedWidth: { type: Number, required: false, default: null },
    helpMessage: { type: String, required: false, default: "" },
    helpCardWidth: { type: Number, required: false, default: 200 },
    helpCardMaxWidth: { type: Number, required: false, default: 200 },
    helpCardTopPixels: { type: Number, required: false, default: 0 },
    helpCardLeftPixels: { type: Number, required: false, default: 0 },
  },
  data() {
    return {
      colorPrimary: null,
      showHelpCard: false,
    };
  },
  computed: {
    ...mapGetters({
      ownerColors: "auth/getOwnerColors",
      userProfile: "auth/getUserProfile",
    }),
    checkHelpTextSettings() {
      let showHelp = false;
      if (
        (this.userProfile.settings.showDetailHelpMessages &&
          this.userProfile.settings.showFormHelpMessages) ||
        (this.userProfile.settings.showFormHelpMessages && this.isForm)
      ) {
        showHelp = true;
      }
      return showHelp;
    },
  },
  watch: {
    showHelpCard() {
      if (this.showHelpCard) {
        window.addEventListener(
          "click",
          () => (this.showHelpCard = false),
          null
        );
      } else {
        window.removeEventListener("click", null, null);
      }
    },
  },
  mounted() {
    this.colorPrimary = this.ownerColors.ownerPrimaryColor;
  },
  methods: {
    setStyle() {
      return `top: ${this.helpCardTopPixels}px; left: ${this.helpCardLeftPixels}px;`;
    },
  },
};
</script>

<style lang="scss" scoped>
.f2g-subtitle {
  position: relative;
  font-weight: 500;
  border-bottom: 1px solid $form-title-underline;
  margin-bottom: 15px !important;

  .helpCard {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3), 0 0 10px rgba(0, 0, 0, 0.096) !important;
    position: absolute;
    z-index: 1000 !important;
    text-align: left;
    text-justify: inter-word;
    line-height: 1.2;
  }
  .icon {
    cursor: pointer;
  }
  .text-field {
    pointer-events: auto;
  }
  .message {
    font-size: 13px;
  }
}

.margin-top {
  margin-top: 25px !important;
}
</style>
